import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            <a href="https://github.com/r-saba/catch-of-the-day">
              Catch of the Day
            </a>{' '}
            - Online fish market. Prices and stock update in real time for all
            logged in users. Built with React and Firebase
          </p>
          <p>
            <a href="https://github.com/r-saba/WayFinder">WayFinder</a> - Mobile
            Campus Guide for Concordia University. Mainly worked on the UI and
            functionality related to searching for locations and beginning a
            travel plan.
          </p>
          <p>
            <a href="https://github.com/r-saba/AI-Projects">
              Artificial Intelligence
            </a>{' '}
            - Collection of projects related to AI. Checkout <a>Degrees</a>.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            <strong>Diff Agency</strong> - Designed and developed Shopify
            ecommerce websites for clients. Websites built using HTML, CSS,
            JavaScript and Liquid
          </p>
          <p>
            <strong>Neptronic</strong> - Worked on an internal web application.
            Implemented a sales rep finder using google maps. Built with ASP.NET
            MVC, HTML, CSS and JavaScript
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Hello! I am the developer behind this website and many others. I
            build apps (desktop, mobile and web). I believe knowledge is power
            and for that reason I am constantly learning as much as I can. Being
            a developer to me means more than simply writing code. A developer
            should be capable of eliciting requirements, communicating with a
            team and writing clean code, just to name a few.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="post"
            action="https://getform.io/f/63299ade-010c-47bd-b1f6-d0c14bf0ec9c"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/ragithsaba/"
                className="icon fa-linkedin"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/r-saba" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
